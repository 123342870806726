<template>
  <report-criteria
    :criteriaRedrawKey="criteriaRedrawKey"
    :populationStore="globalPopulationStore"
    :workflowStore="globalWorkflowStore"
    :globalWorkflowStore="globalWorkflowStore"
    :globalSelector="true"
    @characteristics-set="globalCharacteristicsSet"
    @date-range-set="globalDateRangeSet"
    @data-segment-set="globalSegmentSet"
    @gender-set="globalGenderSet"
    @age-range-set="globalAgeRangeSet"
    @patient-status-set="globalPatientStatusSet"
    class="side-bar-controls"
    :disabled="disabled"
    v-if="mountComplete"
  />
</template>

<script>
  import ReportCriteria from '@/components/filters/ReportCriteria.vue';

  export default {
    components: {
      ReportCriteria
    },
    props: [
      'globalWorkflowStore',
      'globalPopulationStore',
      'workflowStores',
      'populationStores',
      'disabled',
      'criteriaRedrawKey'
    ],
    data() {
      return {
        mountComplete: true
      };
    },
    computed: {
      criteriaRedrawFormatted() {
        return this.criteriaRedrawKey + '_globalcriteria';
      }
    },
    methods: {
      globalGenderSet(value) {
        for (let i in this.workflowStores) {
          this.workflowStores[i].store.setGender(value);
        }

        this.$emit('global-selector-changed', 'gender');
      },
      globalAgeRangeSet(value) {
        for (let i in this.workflowStores) {
          this.workflowStores[i].store.setAgeRange(value);
        }

        this.$emit('global-selector-changed', 'ageRange');
      },
      globalPatientStatusSet(value) {
        for (let i in this.workflowStores) {
          this.workflowStores[i].store.setPatientStatus(value);
        }

        this.$emit('global-selector-changed', 'patientStatus');
      },
      globalDateRangeSet(value) {
        for (let i in this.workflowStores) {
          this.workflowStores[i].store.setDateRange(value);

          if (this.workflowStores[i].store.isInUse()) {
            this.populationStores[i].store.setDirty(true);
          }
        }

        this.$emit('global-selector-changed', 'dateRange');
      },
      globalCharacteristicsSet(value) {
        for (let i in this.workflowStores) {
          if (this.workflowStores[i].store.isInUse()) {
            this.populationStores[i].store.setCurrentPopulationSelection(value);
            this.populationStores[i].store.loadSavedPopulationByFilterData(
              value
            );
          }
        }

        this.$emit('global-selector-changed', 'characteristics');
      },
      globalSegmentSet(value) {
        for (let i in this.workflowStores) {
          if (this.workflowStores[i].store.isInUse()) {
            this.workflowStores[i].store.setDataSegment(value);
            this.populationStores[i].store.setDirty(true);
          }
        }

        this.$emit('global-selector-changed', 'dataSegment');
      }
    }
  };
</script>
